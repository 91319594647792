import { ShiftType } from '@tymbe/schema/enums';
import { ShiftLabels } from '@tymbe/schema/shift-template.interface';
import moment from 'moment';
import * as React from 'react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Address } from './Address';
import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { CardSubHeader } from './Card/CardSubHeader';
import { intl } from './I18n';
import { LabelsList } from './LabelsList';
import { MapLink } from './MapLink';
import { PerkList } from './PerkList';
import RingBadge from './RingBadge';
import Spinner from './Spinner';
import { H1, H2, H3, H4 } from './Styles/BasicElements';
import { HoursBar } from './Styles/HoursBar';
import { Icon } from './Styles/Icon';
import Markdown from './Styles/Markdown';
import { Row } from './Styles/Row';
import { Time } from './Styles/Time';
import { WellBox } from './Styles/WellBox';
import { SurchargeList } from './SurchargeList';
import { Toggleable } from './Toggleable';
import { UtilityList } from './UtilityList';
import CompanyLogo from '../containers/CompanyLogo';
import { styled } from '../styledComponents';
import ThemeInterface from '../themes/theme';
import {
  FinishedStates,
  Money,
  Ring as RingData,
  Tymber,
} from '../types/Tymbe';
import { hasAllPerksFulfilled, hasAllUtilsFulfilled, shiftLabelsText } from '../utils';

enum CardStyle {
  'HOT',
  'DISABLED',
  'AWAITING_CONFIRMATION',
  'CONFIRMED',
  'AWAITING_PAYMENT',
  'COMPLETED',
  'ABSENT_OK',
  'ABSENT_NOK',
  'ALCOHOL_DRUGS',
  'CANCELED_EARLY',
  'CANCELED_LATE',
  'SYSTEM_CANCELED',
  'VACATION',
  'VACATION_AWAITING_CONFIRMATION',
  'VACATION_COMPLETED',
}

export const RingToggleIcon = styled(Icon).attrs({ id: 'toggleIndicator', color: 'white' }) <{ toggled?: boolean }>`
  display: block;
  height: 2rem;
  width: 1rem;
  margin-right: 1rem;
  transition: transform .5s;

  ${({ toggled }) => toggled && 'transform: rotate(90deg);'}
`;

const RingHeader = styled(CardHeader) <{ toggled?: boolean }>`
  display: flex;
  flex-flow: row;
  align-items: stretch;

  & > * {
    flex-grow: 1;
    align-self: center;

    &:last-child, &${Icon} {
      flex-grow: 0;
    }
    &:last-child {
      text-align: right;
      margin-left: 2rem;
    }
  }
`;

const RingSubHeader = styled(CardSubHeader)`
  display: flex;
  flex-flow: row;
  align-items: stretch;
  font-size: 1.1rem;

  & > * {
    flex-grow: 1;
    margin-right: 1rem;

    &:last-child, &:first-child {
      flex-grow: 0;
      align-self: center;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const RingFooter = styled(CardFooter)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  & > *:nth-child(even) {
    text-align: right;
  }
`;

type RingBaseProps = {
  data: RingData;
  tymber: Tymber;
  loading?: boolean;
  expanded?: boolean;
};

type IsFunction = (value: RingData) => ReactNode;
const isFunction = (value: unknown): value is IsFunction =>
  typeof value === 'function';

export type RingProps =
  ({ children?: React.ReactNode; } | { children?: (ring: RingData) => React.ReactNode; })
  & RingBaseProps;

export const RingSpinner: React.FC = () => (
  <Card>
    <Spinner />
    <RingHeader>
      <div>Loading...</div>
      <div>Loading...</div>
      <div>Loading...</div>
      <div>Loading...</div>
    </RingHeader>
    <CardContent>
      <H1>Loading...</H1>
      <H4>Loading...</H4>
    </CardContent>
    <RingFooter>
      <div>Loading...</div>
      <div>Loading...</div>
      <div>Loading...</div>
      <div>Loading...</div>
    </RingFooter>
  </Card>
);

const getCredits = (ring: RingData): Money => ring.credits;

const getStateDescription = (cardStyle: CardStyle | null) => {
  switch (cardStyle) {
    case CardStyle.DISABLED:
      return intl.formatMessage({
        defaultMessage: 'Nesplňujete podmínky',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.AWAITING_CONFIRMATION:
    case CardStyle.VACATION_AWAITING_CONFIRMATION:
      return intl.formatMessage({
        defaultMessage: 'Čeká na potvrzení docházky',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.CONFIRMED:
      return intl.formatMessage({
        defaultMessage: 'K vyplaceni',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.AWAITING_PAYMENT:
      return intl.formatMessage({
        defaultMessage: 'Zpracování platby',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.COMPLETED:
    case CardStyle.VACATION_COMPLETED:
      return intl.formatMessage({
        defaultMessage: 'Potvrzená docházka',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.ABSENT_OK:
      return intl.formatMessage({
        defaultMessage: 'Oml. absence',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.ALCOHOL_DRUGS:
      return intl.formatMessage({
        defaultMessage: 'Nezpůsobilý práce',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.ABSENT_NOK:
      return intl.formatMessage({
        defaultMessage: 'Neoml. absence',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.CANCELED_EARLY:
      return intl.formatMessage({
        defaultMessage: 'Zrušeno v termínu',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.CANCELED_LATE:
      return intl.formatMessage({
        defaultMessage: 'Zrušeno po termínu',
        description: 'Stav ringu (brigády)',
      });
    case CardStyle.SYSTEM_CANCELED:
      return intl.formatMessage({
        defaultMessage: 'Zrušeno systémem',
        description: 'Stav ringu (brigády)',
      });
    default:
      return undefined;
  }
};

const getCardBadge = (cardStyle: CardStyle | null) => {
  switch (cardStyle) {
    case CardStyle.DISABLED:
      return (
        <RingBadge
          key="attention"
          icon="attention"
          title={getStateDescription(cardStyle)}
          iconFg="danger"
          iconBg="gray5"
        />
      );
    case CardStyle.AWAITING_PAYMENT:
    case CardStyle.AWAITING_CONFIRMATION:
    case CardStyle.VACATION_AWAITING_CONFIRMATION:
      return (
        <RingBadge
          key="waiting"
          icon="waiting"
          title={getStateDescription(cardStyle)}
          iconFg="white"
        />
      );
    case CardStyle.CONFIRMED:
      return (
        <RingBadge
          key="money"
          icon="money"
          title={getStateDescription(cardStyle)}
          iconFg="white"
        />
      );
    case CardStyle.COMPLETED:
    case CardStyle.VACATION_COMPLETED:
      return (
        <RingBadge
          key="finished"
          icon="finished"
          title={getStateDescription(cardStyle)}
          iconFg="white"
          iconBg="primary"
        />
      );
    case CardStyle.ABSENT_OK:
    case CardStyle.CANCELED_EARLY:
    case CardStyle.SYSTEM_CANCELED:
      return (
        <RingBadge
          key="false"
          icon="false"
          title={getStateDescription(cardStyle)}
          iconFg="white"
          iconBg="danger"
        />
      );
    case CardStyle.CANCELED_LATE:
    case CardStyle.ALCOHOL_DRUGS:
    case CardStyle.ABSENT_NOK:
      return (
        <RingBadge
          key="false"
          icon="false"
          title={getStateDescription(cardStyle)}
          iconFg="white"
          iconBg="danger2"
        />
      );
    default:
      return null;
  }
};
const getCardStyle = (ring: RingData, tymber: Tymber) => {
  if (ring.type === ShiftType.VACATION) {
    if (ring.finishedState === FinishedStates.AWAITING_CONFIRMATION) {
      return CardStyle.VACATION_AWAITING_CONFIRMATION;
    }
    if (ring.finishedState === FinishedStates.COMPLETED) {
      return CardStyle.VACATION_COMPLETED;
    }
    return CardStyle.VACATION;
  }
  switch (ring.finishedState) {
    case FinishedStates.AWAITING_CONFIRMATION:
      return CardStyle.AWAITING_CONFIRMATION;
    case FinishedStates.CONFIRMED:
      return CardStyle.CONFIRMED;
    case FinishedStates.AWAITING_PAYMENT:
      return CardStyle.AWAITING_PAYMENT;
    case FinishedStates.COMPLETED:
      return CardStyle.COMPLETED;
    case FinishedStates.ABSENT_OK:
      return CardStyle.ABSENT_OK;
    case FinishedStates.ABSENT_NOK:
      return CardStyle.ABSENT_NOK;
    case FinishedStates.ALCOHOL_DRUGS:
      return CardStyle.ALCOHOL_DRUGS;
    case FinishedStates.CANCELED_EARLY:
      return CardStyle.CANCELED_EARLY;
    case FinishedStates.CANCELED_LATE:
      return CardStyle.CANCELED_LATE;
    case FinishedStates.SYSTEM_CANCELED:
      return CardStyle.SYSTEM_CANCELED;
    default:
      if (!hasAllPerksFulfilled(tymber, ring) || !hasAllUtilsFulfilled(tymber, ring)) {
        return CardStyle.DISABLED;
      }
      if (moment(ring.time.start).diff(undefined, 'hours', true) < 24) {
        return CardStyle.HOT;
      }
      return null;
  }
};

const getBadgeBackground = (label: ShiftLabels | string) => {
  switch (label) {
    case ShiftLabels.PHYSICALLY_CHALLENGING_JOB:
      return 'bg-fg-300';
    default:
      return 'bg-primary';
  }
};

export const Ring = styled(
  ({ children, data: ring, loading, tymber, expanded: exp, ...rest }: RingProps) => {
    // we need to use this name because of automation
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const intl = useIntl();
    const [expanded, changeExpanded] = React.useState(exp);
    const credits = getCredits(ring);
    const cardStyle = getCardStyle(ring, tymber);
    const isNotVacation = ring.type !== ShiftType.VACATION;

    let disableNextClick = false;
    const toggle = () => {
      if (!disableNextClick && !exp) {
        changeExpanded(!expanded);
      }
      disableNextClick = false;
    };
    const checkSelect = () => {
      disableNextClick = disableNextClick || !!window.getSelection()?.toString();
    };

    const getCardColor = (): keyof ThemeInterface['colors'] => {
      switch (cardStyle) {
        case CardStyle.VACATION_AWAITING_CONFIRMATION:
        case CardStyle.AWAITING_CONFIRMATION:
        case CardStyle.AWAITING_PAYMENT:
          return 'steel';
        case CardStyle.SYSTEM_CANCELED:
          return 'gray4';
        case CardStyle.CONFIRMED:
          return 'gold';
        case CardStyle.DISABLED:
          return 'gray5';
        case CardStyle.CANCELED_EARLY:
        case CardStyle.ABSENT_OK:
          return 'danger';
        case CardStyle.ALCOHOL_DRUGS:
        case CardStyle.ABSENT_NOK:
        case CardStyle.CANCELED_LATE:
          return 'danger2';
        case CardStyle.VACATION:
          return 'secondary';
        // case CardStyle.HOT:
        //   return 'secondary';
        default:
          return 'primary';
      }
    };

    const badges = [];
    if (getCardBadge(cardStyle)) badges.push('cardBadge');
    if (ring.surcharges?.length) badges.push('surchargeBadge');
    if (ring.labels?.length) badges.push('labelsBadges');

    const startTime = moment(ring.time.start);

    return (
      <Card onMouseDown={checkSelect} onMouseUp={checkSelect} onClick={toggle} color={getCardColor()} {...rest}>
        {loading && <Spinner />}
        <div className="flex flex-col absolute left-full top-[2rem] gap-2">
          {badges.length > 0 && badges.map((item) => {
            if (item === 'cardBadge') {
              return getCardBadge(cardStyle);
            }
            if (item === 'surchargeBadge') {
              return <RingBadge key="money" icon="money" />;
            }
            if (item === 'labelsBadges') {
              return ring.labels?.map((label) => (
                <RingBadge
                  key={label}
                  icon={label}
                  title={shiftLabelsText[label]}
                  backgroundColor={getBadgeBackground(label)}
                />
              ));
            }
            return undefined;
          })}
        </div>

        <RingHeader>
          <RingToggleIcon toggled={expanded} />
          <H2 style={{ whiteSpace: 'nowrap' }}>{intl.formatMessage(
            {
              defaultMessage: '{date, date, short}',
              description: 'Datum brigády v hlaviče karty brigády, používá se co nejkratší zápis data, např:"10.12.22"',
            },
            {
              date: startTime,
            },
          )}
          </H2>
          <H3>{
            startTime > moment()
              ? intl.formatMessage(
                {
                  defaultMessage: '{date, time, ::ccccc}',
                  description: 'Název dne týdnu v hlaviče karty brigády, např: "pondělí"',
                },
                {
                  date: startTime,
                },
              )
              : getStateDescription(cardStyle)
          }
          </H3>
        </RingHeader>
        <RingSubHeader>
          <div>
            {intl.formatMessage(
              {
                defaultMessage: '{start, time, ::HHmm} - {end, time, ::HHmm}',
                description: 'Začatek a konec směny. Zobrazovene v záhlaví karty brigády. Napr: "06:00 - 14:00"',
              },
              { start: startTime, end: moment(ring.time.end) },
            )}
          </div>
          <HoursBar hours={ring.duration} />
          <Time>
            <Icon id="clock" color="black" />

            {intl.formatMessage(
              {
                defaultMessage: `{worked_hours, number, ::.# unit/hour} {overtime, plural,
                  =0 {}
                  other {+ {overtime, number, ::.# unit/hour}} }`,
                description: 'Odpracovane hodiny. Zobrazovene v záhlaví karty brigády.'
                  + ' Napr: "6,5 h" nebo "6,5 h + 1 h" v případě přesčasu',
              },
              { worked_hours: (ring.duration + ring.durationOver), overtime: ring.durationCredit },
            )}
          </Time>
        </RingSubHeader>
        <CardContent style={{ paddingBottom: 0 }}>
          <Row alignItems="center">
            <CompanyLogo
              path={ring.company.logo}
              alt={ring.company.name}
            />
            <span>{ring.title}</span>
          </Row>
          <WellBox centered outline style={{ cursor: 'pointer' }}><H3>{ring.position}</H3></WellBox>
          {!isNotVacation && (
            <>
              <div className="text-center">
                <div className="self-center">
                  <div className="clearFix" style={{ margin: '.1rem .5rem' }}>
                    <strong>
                      {intl.formatMessage(
                        {
                          defaultMessage: '{money_shift} Kč/směna',
                          description: 'Výdělek za směnu. Napr: "585 Kč/směna"',
                        },
                        {
                          money_shift: intl.formatNumber(ring.money.shift, {
                            style: 'decimal',
                          }),
                        },
                      )}
                    </strong>
                  </div>
                </div>
                <WellBox inline color="gray3">
                  {intl.formatMessage(
                    {
                      defaultMessage: '<currency>{money_hour}</currency> Kč/h',
                      description: 'Vydělek za hodinu. Např: "58,50 Kč/h"',
                    },
                    {
                      money_hour: intl.formatNumber(ring.money.hour, {
                        style: 'decimal',
                      }),
                    },
                  )}
                  {ring.finishedState !== FinishedStates.COMPLETED ? '*' : ''}
                </WellBox>
                <div className="text-xs mt-1">
                  {ring.finishedState !== FinishedStates.COMPLETED && (
                    <>
                      {intl.formatMessage({
                        defaultMessage: '* hodinová sazba není finální a může se lišit na základě vybraného datumu',
                        description: 'Poznámka k dovolenkovým směnám',
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {isNotVacation && (
            <>
              <div style={{ alignSelf: 'center' }}>
                <WellBox inline noWrap color="primary">
                  <WellBox inline color="gray3">
                    {intl.formatMessage(
                      {
                        defaultMessage: '<currency>{money_hour}</currency> Kč/h',
                        description: 'Vydělek za hodinu. Např: "58,50 Kč/h"',
                      },
                      {
                        money_hour: intl.formatNumber(ring.money.hour, {
                          style: 'decimal',
                          minimumFractionDigits: ring.money.hour % 1 ? 2 : 0,
                          maximumFractionDigits: ring.money.hour % 1 ? 2 : 0,
                        }),
                      },
                    )}
                  </WellBox>
                  {intl.formatMessage(
                    {
                      defaultMessage: '<currency>{credit_hour}</currency> Kr/h',
                      description: 'Vydělek kreditů za hodinu. Např: "58,50 Kr/h"',
                    },
                    {
                      credit_hour: intl.formatNumber(credits.hour, {
                        style: 'decimal',
                        minimumFractionDigits: credits.hour % 1 ? 2 : 0,
                        maximumFractionDigits: credits.hour % 1 ? 2 : 0,
                      }),
                    },
                  )}
                </WellBox>
              </div>
            </>
          )}
          <Toggleable toggled={!!expanded}>
            <hr />
            {isNotVacation && (
              <>
                <H3>
                  <Icon id="workplace" size="1.5em" style={{ marginRight: '.5rem' }} />
                  {intl.formatMessage({
                    defaultMessage: 'Provozovna',
                    description: 'Titulek sekce karty brigády obsahující adresu provozovny',
                  })}
                </H3>
                <MapLink
                  searchLocation={`${ring.address.street.join(' ')}, ${ring.address.city}, ${ring.address.country}`}
                >
                  <Address {...ring.address} nowrap />
                </MapLink>
                <hr />
              </>
            )}
            {!!ring.surcharges?.length && (
              <>
                <SurchargeList surcharges={ring.surcharges} />
                <hr />
              </>
            )}
            {!!ring.labels?.length && (
              <><LabelsList labels={ring.labels} />
                <hr />
              </>
            )}
            {!!ring.perks?.length && (
              <>
                <PerkList tymber={tymber} perks={ring.perks} ring={ring} />
                <hr />
              </>
            )}
            {!!ring.utilities?.length && (
              <>
                <UtilityList tymber={tymber} utilities={ring.utilities} ring={ring} />
                <hr />
              </>
            )}
            {isNotVacation && (
              <>
                <H3>
                  <Icon id="info" size="1.5rem" style={{ marginRight: '.5rem' }} />
                  {intl.formatMessage(
                    {
                      defaultMessage: 'Volná místa: {free_positions}',
                      description: 'Volná místa na inzerované brigádě',
                    },
                    { free_positions: ring.freePositions > 3 ? '3+' : ring.freePositions },
                  )}
                </H3>
              </>
            )}
            {ring.description && <Markdown>{ring.description}</Markdown>}
            {!!ring.state && ring.instructions && (<Markdown>{ring.instructions}</Markdown>)}
            {!!ring.state && ring.contact ? intl.formatMessage(
              {
                defaultMessage: 'Kontakt: {contact}',
                description: 'Kontaktakti informace brigády, vetsinou obsahuje telefoní číslo na koordinátora brigády.',
              },
              { contact: ring.contact },
            ) : ''}
            <hr />
            {isFunction(children) ? children(ring) : children}
          </Toggleable>
        </CardContent>
        <RingFooter />
      </Card>
    );
  },
)`max-width: 450px;`;
